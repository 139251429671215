import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home.component";
import {UserDetailsProvider} from './context/UserContext'


require('dotenv').config({path:__dirname+'/./../../.env'})

function App() {
    return (
        <Router>
            <div className="container">
                <UserDetailsProvider>
                    <Route exact path="/" component={Home} />
                    <Redirect from="*" to="/" />
                </UserDetailsProvider>
            </div>
        </Router>
    );
}

export default App;
