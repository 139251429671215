import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import logo from "../assets/tezarekt_bw.png";

export default class Footer extends Component {
    render() {
        return (
            <div className="w-100 d-flex">
                <div style={{fontFamily: "PressStart2P-Regular", fontSize:'2.3vmin'}} className='d-flex '>
                    <a href='https://tezarekt.s3.ap-southeast-1.amazonaws.com/frontend_files/Tezarekt+White+Paper.pdf' className="font-weight-bold text-light small mr-3 mr-md-4 align-self-center"><div>Whitepaper</div></a>
                </div>
                <div className='col-md-1 d-none d-md-block'/>
            </div>
        );
    }
}