import React, {Component} from "react";
import logo from "../assets/tezarekt-16bit.png"
import styles from "../App.module.css";

export default class Home extends Component {
    render() {
        return (
            <>
                <div className={styles.arcade+' d-flex justify-content-between pt-4'}>
                    <div><span>1 UP</span></div>
                    <div><span>HIGHSCORE 000000</span></div>
                    <div className={styles.arcadeBlink}><span>INSERT COIN</span></div>
                </div>

                <div className={styles.centerPage}>
                    <div className='col-12 text-center'>
                        <img alt='' src={logo} className='w-100' />
                    </div>
                    <div className='col-12 text-white text-center'>
                        <div className={styles.coming}>UND<span className={styles.soon}>ER MAIN</span>TENANCE</div>
                    </div>
                </div>
            </>
        )
    }
}
