import React, { useState, useMemo } from 'react';

export const UserContext = React.createContext();

export function UserDetailsProvider({children}) {
    const [userDetails, setUserDetails] = useState(null);
    const providerValue = useMemo(()=> ({userDetails,setUserDetailsLogged}), [userDetails, setUserDetailsLogged]);

    function setUserDetailsLogged(input) {
        setUserDetails(input);
    }

    return (
        <UserContext.Provider value={providerValue}>
                {children}
        </UserContext.Provider>
    );
}