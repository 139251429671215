import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer.component"


function FootBar() {
    return (
        <Router>
            <div className="container">
                <Footer />
            </div>
        </Router>
    );
}

export default FootBar;